﻿import jquery from 'jquery';
import HabEvents from '../../Global/habitaclia-events';

export default class PWA {

    constructor()
    {
        if('serviceWorker' in navigator) {
            navigator.serviceWorker
            .register('/sw.js')
            .then(function() {
                console.log("Service Worker registered successfully");
            })
            .catch(function() {
                console.log("Service worker registration failed");
            });
            this.registerEvents();
        }
       
    }

    registerEvents()
    {
        window.addEventListener('appinstalled', () => {
            jquery.publish(HabEvents.EventsEnum.PWAEvent, 'instalada');
        });
        window.addEventListener('beforeinstallprompt', (e) => {
            jquery.publish(HabEvents.EventsEnum.PWAEvent, 'pregunta-instalar');
            e.userChoice.then((choiceResult)=> {
                jquery.publish(HabEvents.EventsEnum.PWAEvent, choiceResult.outcome);
            });
        });
    }

}

/* jshint unused: false */
const PWAInstance = new PWA();